(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("babylonjs"));
	else if(typeof define === 'function' && define.amd)
		define("babylonjs-gui", ["babylonjs"], factory);
	else if(typeof exports === 'object')
		exports["babylonjs-gui"] = factory(require("babylonjs"));
	else
		root["BABYLON"] = root["BABYLON"] || {}, root["BABYLON"]["GUI"] = factory(root["BABYLON"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 